import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import DriveMaximumRestData from '../Data/DriveMaximumRestData'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppDriveMaximumRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .ContractsModuleSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .HeadingEamilContractsModule {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaContractsModuleStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaContractsModuleStyleOne{
    max-width: 1150px;
    padding: 0px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .SubParaPonits {
    color: #047cc2;
    padding: 10px 0px 10px 0px;
    font-weight: bold;
    font-size: 21px;
  }
`

export const AppDriveMaximumRest = () => {
  return (
    <AppDriveMaximumRestWapper>
      <div>
        {DriveMaximumRestData.map((data, index) => {
          return (
            <div className='MainEmailProcurementSection' key={index}>
              <div className='ContractsModuleSectionEmail'>
                <ReUsePtag para={data.ParaOne} paraStyle='LeadParaContractsModuleStyleOne' />
                <ReUsePtag para={data.ParaTwo} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaThree} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaFour} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaSix} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingFour} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaSeven} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingFive} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaEight} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingSix} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaNine} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingSeven} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaTen} paraStyle='LeadParaContractsModuleStyle' />
              </div>
            </div>
          )
        })}
      </div>
    </AppDriveMaximumRestWapper>
  )
}

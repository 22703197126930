import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Icon: <FaArrowCircleRight />,
    ParaOne: "Annual Operating Plans are a months-long process, which once set, are challenging to move to the rapid pace of change we’ve seen in the past two years, let alone the past month.  With certain commodity prices in dynamic upheaval, having a comprehensive, digitally-enabled Annual Operating Plan backed with actionable data can help the business and its various branches stay on course. This is where Raindrop’s Planning Module comes in, seamlessly integrating budgeting, planning, and forecasting activities to manage your corporate finances – whether it be a single project or all the way through your entire Annual Operating Plan. Raindrop’s Planning Module enables you to develop any financial model, and share it with your team or department to keep everyone tracking to the plan, even in a dynamic environment. Raindrop’s Planning Module provides immediate access to real-time advanced spend analytics. Know who your money is going to, and how much you’re spending with each external entity. Raindrop’s powerful business intelligence enables your company to plan and manage commodities, spend categories, create supplier consolidation strategies, and build Predictive Sourcing strategies across each functional area.",
    ParaTwo: "On a brushstroke, the Annual Operating Plan is a collaborative effort across all business leaders in the organization and sets the tone for the year. The AOP should drive the financials; it provides the necessary glue that aligns the organization on strategy, measurements of success, and the resources necessary to execute. AOP development is an important muscle that leaders build over time, and a well-run AOP not only delivers on the alignment objective but also builds confidence with your team and your board that goals can be achieved.",
    HeadingOne: "Raindrop is the common language aligning corporate AOP to realize and achieve shared objectives",
    ParaThree: "As we’ve seen over the past year, the challenges and expectations from Finance, Sourcing, IT, and other corporate organizations have increased to support their newly decentralized workforces. Inject issues like global trade uncertainty, increasing tariff pressures, and external factors like chip shortages and supply chain bottlenecks, have been forced to be more strategic and predictive than ever before. How is this done? It all starts with good planning and the technology to help drive proper spend management visibility with respect to the procuring of goods and services.",
    HeadingTwo: "Hedge Against Inflation",
    ParaFour: "As the world works hard to get coronavirus under control, there’s new post-pandemic concerns, namely – inflation and military conflict, impacting every company around the globe. More simply put, economic recovery is now at the top of the priority list, all the way from large enterprises down to the small shops on Main Street, with inflation and dynamic commodity pricing impacting them all. Sound financial annual planning creates the baseline from which every company’s external supplier spend is driven, including existing run rate spend, to net-new capital investments. Raindrop’s Planning Module provides complete budget planning capabilities to create multiple financial scenario models over time. Let’s say the cost of lumber has risen 40% this year and you manufacture furniture; how do you react to the market and plan accordingly becomes critical to the success of your company. However, when a market price is known and can be planned over the course of a fiscal year, even when your company must buy more quantities of a product or service, then Raindrop’s Planning Module can immediately show you how each decision impacts your financial plan, displayed in real-time.",
    HeadingThree: "Collaborate for Success",
    ParaSix: "Raindrop’s Planning module is designed to promote collaboration within your team, as well as outside of your team, always ensuring you are in control of your data. Raindrop provides collaborative chat functionality, which is native to the platform, as well as integrates with other collaboration tools. This rapidly bridges traditional communication gaps between different functional groups and external suppliers by providing improved two-way communication.",
    HeadingFour: "Data-Driven Spend Analytics",
    ParaSeven: "Raindrop’s Planning Module provides immediate access to advanced spend analytics through visualization, like charts, graphs, tables. Know who your money is going to, and how much you’re spending with each external entity. Raindrop’s powerful business intelligence enables your company to plan and manage commodities, spend categories, create supplier consolidation strategies, and build predictive sourcing strategies across each functional area. Raindrop provides aggregated, normalized, and enriched spend data to analyze and interrogate your data across multiple standard and custom dashboards. Bring more spending under management and easily identify savings and efficiencies while being alerted to supply risks and compliance issues.",
    HeadingFive: "Predictive Planning",
    ParaEight: "Raindrop’s Planning module lets you model a unified financial view of your global enterprise spend across different regions, currency types, suppliers, commodities, and more. Easily analyze the spend data in one place and utilize data insights for driving business growth. Raindrop’s powerful business intelligence enables your company to plan and manage commodities, spend categories, create supplier consolidation strategies, and build predictive sourcing strategies across each functional area.  Translate strategy to action and measure adherence to the published plan.",
    HeadingSix: "Identify and Execute on Cost Reduction Targets",
    ParaNine: "With Raindrop’s guided machine learning, get savings recommendations on your upcoming purchase requirements. Raindrop’s Machine Learning technology helps to identify your commodities' historic and current discounts and buying patterns and provides precise recommendations for cost savings and cost containment rates.",
    HeadingSeven: "Quickly Set-up and Start Planning",
    ParaTen: "Raindrop provides several templates which are geared to enable you to do your business your own way. Raindrop’s Planning is designed as a dynamic user interface to quickly and easily visualize a series of financial models capable of turning data into intelligence, all in a simple and powerful interface. Users can leverage Raindrop’s Planning templates to load any spreadsheet data and rapidly acquire the necessary information. Raindrop’s ease of deployment and template libraries, provide users the capabilities needed to rapidly plan and attain value. Avoid numerous administrative overheads with ease of data loading from various systems.",
  },
]

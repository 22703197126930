import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppDriveMaximumRest } from './AppDriveMaximumRest'

const AppDriveMaximumWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .DriveMaximumSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .DriveMaximumNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .DriveMaximumSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppDriveMaximumWithNav = () => {
  return (
    <AppDriveMaximumWithNavWapper>
      <div className='DriveMaximumSection'>
        <div className='DriveMaximumNav'>
          <AppSideNavEmailName />
        </div>
        <div className='DriveMaximumSectionContent'>
          <AppDriveMaximumRest />
        </div>
      </div>
    </AppDriveMaximumWithNavWapper>
  )
}
